import { Tabs } from "antd";
import styled from "styled-components";

const MainTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
    .ant-tabs-nav-wrap {
      background: #f5f5f5;
    }
  }
`;

export default MainTabs;
