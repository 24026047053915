import React, { useEffect, useState } from "react";
import { Spin, Input } from "antd";
import { getEvents } from "../../../apis/eventApi";
import DashboardTable from "../../../components/Table/DashboardTable";

const { Search } = Input;

const Events = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [pageObj, setPageObj] = useState({ page: 1, limit: "15" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await getEvents(
        search,
        pageObj.page - 1,
        pageObj.limit,
        setLoading
      );

      if (response) {
        setTotal(response.total);
        setData(response.events);
      }
    };

    getData();
  }, [search, pageObj]);

  const columns = [
    {
      title: "Date",
      key: "day",
      dataIndex: "day",
      width: "10%",
      align: "center",
    },
    {
      title: "Ordo",
      key: "ordo",
      dataIndex: "ordo",
      width: "5%",
      align: "center",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Precedence",
      key: "precedence_id",
      dataIndex: "precedence_id",
      width: "7%",
      align: "center",
    },
    {
      title: "Color",
      key: "color",
      dataIndex: "color",
      width: "5%",
      align: "center",
    },
    {
      title: "Rank",
      key: "rank_name",
      dataIndex: "rank_name",
      width: "7%",
      align: "center",
    },
    {
      title: "Season",
      key: "season_name",
      dataIndex: "season_name",
      width: "7%",
      align: "center",
    },
    {
      title: "Week in Season",
      key: "week_in_season",
      dataIndex: "week_in_season",
      width: "7%",
      align: "center",
    },
    {
      title: "Day of Week",
      key: "dow",
      dataIndex: "dow",
      width: "7%",
      align: "center",
    },
    {
      title: "Commons",
      key: "commons",
      dataIndex: "commons",
      width: "7%",
      align: "center",
    },
    {
      title: "Slug",
      key: "slug",
      dataIndex: "slug",
      width: "10%",
      align: "center",
    },
  ];

  const handleChange = async (page, pageSize) => {
    setPageObj((oldState) => ({
      ...oldState,
      page: page,
      limit: pageSize,
    }));
  };

  const handleSearch = async (value) => {
    setSearch(value);
    setPageObj((oldState) => ({
      ...oldState,
      page: 1,
    }));
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Search placeholder="Search" onSearch={handleSearch} enterButton />
          </div>
        </div>
      </div>

      <div>
        <Spin spinning={loading}>
          <DashboardTable
            bordered
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1200,
            }}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageObj.limit,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: total,
              current: pageObj.page,
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setPageObj({ page: current, limit: size });
              },
              onChange: (page, pageSize) => {
                handleChange(page, pageSize);
              },
            }}
            rowKey={(record) => record.day}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Events;
