import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getCalendar = async (
  ordo,
  start_date,
  end_date,
  page,
  limit,
  setLoading
) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/v2/calendar/${ordo}?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`
    );
    setLoading(false);

    return res.data;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const createCalendarEvent = async (data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/calendar`,
      data
    );

    if (res.status === 200) {
      Notification({
        type: "success",
        message: "Your action is saved! You can check it 'Overides' tab!",
      });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const updateCalendarEvent = async (id, data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.put(
      `${process.env.REACT_APP_API}/v2/calendar/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({
        type: "success",
        message: "Your action is saved! You can check it 'Overides' tab!",
      });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const deleteCalendarEvent = async (id, data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/calendar/delete/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({
        type: "success",
        message: "Your action is saved! You can check it 'Overides' tab!",
      });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};
