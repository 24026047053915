import React, { useEffect } from "react";
import { Form, Modal, Input, Select, InputNumber } from "antd";
import {
  createCelebration,
  updateCelebration,
} from "../../../apis/celebrationApi";

const CelebrationModal = ({
  isNew,
  editData,
  isModalOpen,
  setIsModalOpen,
  setCurrentDate,
  ordoOptions,
  rankOptions,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNew) {
      form.setFieldsValue(editData);
    }
  }, [isNew, editData, form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const onOk = async () => {
    form
      .validateFields()
      .then(async (data) => {
        if (isNew) {
          await createCelebration(data);
        } else {
          await updateCelebration(editData.id, data);
        }
        form.resetFields();
        setIsModalOpen(false);
        setCurrentDate(new Date());
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancel = async () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Form name="form" {...formItemLayout} variant="filled" form={form}>
      <Modal
        title={isNew ? "New Celebration" : "Edit Celebration"}
        open={isModalOpen}
        okText="Ok"
        onOk={onOk}
        onCancel={onCancel}
        width={600}
      >
        <Form.Item
          label="Ordo"
          name="ordo"
          initialValue={"gen"}
          rules={[
            { required: true, message: "Please select celebration ordo!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            placeholder="Select a Ordo"
            optionFilterProp="children"
            defaultValue={"gen"}
            options={ordoOptions}
            style={{ width: "100px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: "Please input celebration title!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Precedence ID"
          name="precedence_id"
          rules={[
            { required: true, message: "Please input the precedence_id!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <InputNumber min={1} max={13} />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: true, message: "Please input color!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rank"
          name="rank_name"
          initialValue={"Solemnity"}
          rules={[{ required: true, message: "Please select the rank!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            placeholder="Select a Rank"
            optionFilterProp="children"
            defaultValue={"Solemnity"}
            options={rankOptions}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Commons"
          name="commons"
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            { required: true, message: "Please input celebration slug!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Placement"
          name="placement"
          rules={[
            { required: true, message: "Please input celebration placement!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default CelebrationModal;
