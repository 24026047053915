import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getCelebrations = async (
  ordo,
  search,
  page,
  limit,
  setLoading
) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/v2/celebrations/ordos/${ordo}?search=${search}&page=${page}&limit=${limit}`
    );
    setLoading(false);

    return res.data;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const createCelebration = async (data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/celebrations`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "New celebration is created!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const updateCelebration = async (id, data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.put(
      `${process.env.REACT_APP_API}/v2/celebrations/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Celebration is updated!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const deleteCelebration = async (id) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.delete(
      `${process.env.REACT_APP_API}/v2/celebrations/${id}`
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Celebration is deleted!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};
