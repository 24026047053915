import { Table } from "antd";
import styled from "styled-components";

const DashboardTable = styled(Table)`
  & th {
    padding: 8px !important;
    .ant-table-selection {
      flex-direction: row;
      .ant-table-selection-extra {
        position: relative;
        margin-inline-start: 0px;
      }
    }
  }
  & td {
    padding: 4px !important;
    .ant-table-row-expand-icon {
      margin-inline-end: 0px !important;
    }
  }
`;

export default DashboardTable;
