import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getEvents = async (search, page, limit, setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/v2/events?search=${search}&page=${page}&limit=${limit}`
    );
    setLoading(false);

    return res.data;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const createEvent = async (data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/events`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "New Event is created!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const updateEvent = async (id, data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.put(
      `${process.env.REACT_APP_API}/v2/events/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Event is updated!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const deleteEvent = async (id) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.delete(
      `${process.env.REACT_APP_API}/v2/events/${id}`
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Event is deleted!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};
