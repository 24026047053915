import { notification } from "antd";

const openNotification = (type, message) => {
  return notification[type]({
    message: type.charAt(0).toUpperCase() + type.slice(1),
    description: message,
  });
};

const Notification = ({ type, message }) => (
  <>{openNotification(type, message)}</>
);

export default Notification;
