import React, { useEffect, useState } from "react";
import { Spin, Button, Input, Tag } from "antd";
import { PlayCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getOveridesByPagination,
  executeOverides,
  deleteOverides,
} from "../../../apis/overideApi";
import DashboardTable from "../../../components/Table/DashboardTable";
import isAdministrator from "../../../utils/isAdministrator";

const { Search } = Input;

const Overides = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [pageObj, setPageObj] = useState({ page: 1, limit: "15" });
  const [selectedRowKeys, setSelectedKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const getData = async () => {
      const response = await getOveridesByPagination(
        search,
        pageObj.page - 1,
        pageObj.limit,
        setLoading
      );

      if (response) {
        setTotal(response.total);
        setData(response.events);
      }
    };

    getData();
  }, [search, pageObj, currentDate]);

  const columns = [
    {
      title: "Date",
      key: "day",
      dataIndex: "day",
      width: "8%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Ordo",
      key: "ordo",
      dataIndex: "ordo",
      width: "3%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Precedence",
      key: "precedence_id",
      dataIndex: "precedence_id",
      width: "6%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Color",
      key: "color",
      dataIndex: "color",
      width: "5%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Rank",
      key: "rank_name",
      dataIndex: "rank_name",
      width: "7%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Season",
      key: "season_name",
      dataIndex: "season_name",
      width: "7%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Week in Season",
      key: "week_in_season",
      dataIndex: "week_in_season",
      width: "7%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Week in Psalter",
      key: "week_in_psalter",
      dataIndex: "week_in_psalter",
      width: "7%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Commons",
      key: "commons",
      dataIndex: "commons",
      width: "5%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Lable/Slug",
      key: "slug",
      dataIndex: "slug",
      width: "9%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Dominical",
      key: "dominical_letter",
      dataIndex: "dominical_letter",
      width: "5%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Year Cycle",
      key: "year_cycle_letter",
      dataIndex: "year_cycle_letter",
      width: "5%",
      align: "center",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Actions",
      key: "action",
      dataIndex: "action",
      width: "5%",
      align: "center",
      render: (text) => {
        switch (text) {
          case "create":
            return (
              <Tag color="#108ee9" key={text}>
                {text.toUpperCase()}
              </Tag>
            );
          case "update":
            return (
              <Tag color="#87d068" key={text}>
                {text.toUpperCase()}
              </Tag>
            );
          case "delete":
            return (
              <Tag color="#f00" key={text}>
                {text.toUpperCase()}
              </Tag>
            );
        }
      },
    },
  ];

  const onExecute = async () => {
    if (selectedRowKeys.length > 0) {
      executeOverides(selectedRowKeys, setLoading);
      setSelectedKeys([]);
      setCurrentDate(new Date());
    }
  };

  const onDelete = async () => {
    if (selectedRowKeys.length > 0) {
      deleteOverides(selectedRowKeys, setLoading);
      setSelectedKeys([]);
      setCurrentDate(new Date());
    }
  };

  const onSelectChange = (newKeys) => {
    setSelectedKeys(newKeys);
  };

  const handleChange = async (page, pageSize) => {
    setPageObj((oldState) => ({
      ...oldState,
      page: page,
      limit: pageSize,
    }));
  };

  const handleSearch = async (value) => {
    setSearch(value);
    setPageObj((oldState) => ({
      ...oldState,
      page: 1,
    }));
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Search placeholder="Search" onSearch={handleSearch} enterButton />
          </div>
          {isAdministrator() ? (
            <div className="d-flex gap-1">
              <Button
                type="primary"
                icon={<PlayCircleOutlined />}
                onClick={onExecute}
              >
                Execute
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={onDelete}
              >
                Delete
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <Spin spinning={loading}>
          <DashboardTable
            bordered
            columns={columns}
            dataSource={data?.map((row, index) => ({
              ...row,
              index: index + 1 + (pageObj.page - 1) * pageObj.limit,
            }))}
            scroll={{
              x: 1200,
            }}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageObj.limit,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: total,
              current: pageObj.page,
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setPageObj({ page: current, limit: size });
              },
              onChange: (page, pageSize) => {
                handleChange(page, pageSize);
              },
            }}
            rowKey={(record) => record.id}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              selections: [
                {
                  key: "create",
                  text: "Select All Created Events",
                  onSelect: (changeableRowKeys) => {
                    let newKeys = [];
                    newKeys = changeableRowKeys.filter((_, index) => {
                      if (data[index].action == "create") {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    setSelectedKeys(newKeys);
                  },
                },
                {
                  key: "udpate",
                  text: "Select All Updated Events",
                  onSelect: (changeableRowKeys) => {
                    let newKeys = [];
                    newKeys = changeableRowKeys.filter((_, index) => {
                      if (data[index].action == "update") {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    setSelectedKeys(newKeys);
                  },
                },
                {
                  key: "delete",
                  text: "Select All Deleted Events",
                  onSelect: (changeableRowKeys) => {
                    let newKeys = [];
                    newKeys = changeableRowKeys.filter((_, index) => {
                      if (data[index].action == "delete") {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    setSelectedKeys(newKeys);
                  },
                },
                {
                  key: "today",
                  text: "Today Events",
                  onSelect: (changeableRowKeys) => {
                    let newKeys = [];
                    let now = new Date();
                    now.setHours(0, 0, 0, 0);
                    newKeys = changeableRowKeys.filter((_, index) => {
                      if (
                        new Date(data[index].created_at).getTime() >
                        now.getTime()
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    setSelectedKeys(newKeys);
                  },
                },
              ],
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Overides;
