import React, { useEffect, useState } from "react";
import { Select, Spin, Button, DatePicker, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getCalendar, deleteCalendarEvent } from "../../../apis/calendarApi";
import { getRanks } from "../../../apis/rankApi";
import { getSeasons } from "../../../apis/seasonApi";
import { getOrdos } from "../../../apis/ordoApi";
import DashboardTable from "../../../components/Table/DashboardTable";
import isAdministrator from "../../../utils/isAdministrator";
import CalendarModal from "./CalendarModal";

const { RangePicker } = DatePicker;

const Calendar = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [ordo, setOrdo] = useState("gen");
  const [dataRange, setDataRange] = useState({
    start_date: "2024-01-01",
    end_date: "2024-12-31",
  });
  const [pageObj, setPageObj] = useState({ page: 1, limit: "15" });
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [editData, setEditData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ordoOptions, setOrdoOptions] = useState([
    { label: "Gen", value: "Gen" },
  ]);
  const [ordoDescription, setOrdoDescription] = useState(
    "General Roman Calendar for the Roman Catholic Church"
  );
  const [seasonOptions, setSeasonOptions] = useState([
    { label: "Advent", value: "Advent" },
  ]);
  const [rankOptions, setRankOptions] = useState([
    { label: "Solemnity", value: "Solemnity" },
  ]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const convertData = (data) => {
    let result = [];

    for (let key in data) {
      let wholeItem = data[key].find((item) => "ordo" in item);
      if (data[key].length === 1) {
        result.push({
          date: key,
          ...data[key][0],
        });
      } else {
        result.push({
          ordo: ordo,
          color: wholeItem.color,
          week_in_psalter: wholeItem.week_in_psalter,
          date: key,
          children: data[key].slice(1),
          ...data[key][0],
        });
      }
    }

    return result;
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getCalendar(
        ordo,
        dataRange.start_date,
        dataRange.end_date,
        pageObj.page - 1,
        pageObj.limit,
        setLoading
      );

      if (response) {
        setTotal(response.total);
        setData(convertData(response.events));
      }

      const ordos = await getOrdos(setLoading);
      let options = ordos?.map((item) => ({
        value: item.id,
        label: item.id,
        description: item.description,
      }));
      setOrdoOptions([...options]);

      const ranks = await getRanks(setLoading);
      let r_options = ranks?.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setRankOptions([...r_options]);

      const seasons = await getSeasons(setLoading);
      let s_options = seasons?.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setSeasonOptions([...s_options]);
    };

    getData();
  }, [pageObj, dataRange, ordo, currentDate]);

  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: "7%",
      align: "center",
    },
    {
      title: "Ordo",
      key: "ordo",
      dataIndex: "ordo",
      width: "2%",
      align: "center",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Precedence",
      key: "precedence",
      dataIndex: "precedence",
      width: "6%",
      align: "center",
    },
    {
      title: "Color",
      key: "color",
      dataIndex: "color",
      width: "4%",
      align: "center",
    },
    {
      title: "Rank",
      key: "rank",
      dataIndex: "rank",
      width: "7%",
      align: "center",
    },
    {
      title: "Season",
      key: "season",
      dataIndex: "season",
      width: "7%",
      align: "center",
    },
    {
      title: "Week in Season",
      key: "week_in_season",
      dataIndex: "week_in_season",
      width: "7%",
      align: "center",
    },
    {
      title: "Week in Psalter",
      key: "week_in_psalter",
      dataIndex: "week_in_psalter",
      width: "7%",
      align: "center",
    },
    {
      title: "Day of Week",
      key: "dow",
      dataIndex: "dow",
      width: "6%",
      align: "center",
    },
    {
      title: "Commons",
      key: "commons",
      dataIndex: "commons",
      width: "5%",
      align: "center",
    },
    {
      title: "Lable/Slug",
      key: "label",
      dataIndex: "label",
      width: "9%",
      align: "center",
    },
    {
      title: "Dominical",
      key: "dominical_letter",
      dataIndex: "dominical_letter",
      width: "5%",
      align: "center",
    },
    {
      title: "Year Cycle",
      key: "year_cycle_letter",
      dataIndex: "year_cycle_letter",
      width: "5%",
      align: "center",
    },
    {
      title: "Actions",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <center>
          <Button
            type="primary"
            icon={<EditOutlined style={{ width: "12px", height: "12px" }} />}
            style={{ marginRight: "4px", width: "20px", height: "20px" }}
            onClick={() => onEdit(record)}
          />
          <Popconfirm
            title="Delete Celebration"
            description="Are you sure to delete this celebration?"
            onConfirm={() => onDelete(record)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              danger
              icon={
                <DeleteOutlined style={{ width: "12px", height: "12px" }} />
              }
              style={{ marginRight: "4px", width: "20px", height: "20px" }}
            />
          </Popconfirm>
        </center>
      ),
    },
  ];

  const onNew = () => {
    setIsNew(true);
    setIsModalOpen(true);
  };

  const onEdit = (record) => {
    setIsNew(false);
    let editData = record;
    editData.location = ordo;
    setEditData(editData);
    setIsModalOpen(true);
  };

  const onDelete = (record) => {
    const data = {
      color: record.color,
      commons: record.commons,
      day: dayjs(record.date).format("YYYY-MM-DD"),
      dow: record.dow,
      location: ordo,
      ordo: record.ordo,
      precedence_id: record.precedence,
      rank_name: record.rank,
      season_name: record.season,
      slug: record.label,
      title: record.title,
      year: dayjs(record.date).year(),
    };

    deleteCalendarEvent(record.id, data);
    setCurrentDate(new Date());
  };

  const handleChange = async (page, pageSize) => {
    setPageObj((oldState) => ({
      ...oldState,
      page: page,
      limit: pageSize,
    }));
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleDateChange = (dates, dateStrings) => {
    setDataRange((oldState) => ({
      ...oldState,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    }));
    setPageObj((oldState) => ({
      ...oldState,
      page: 1,
    }));
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <div className="d-flex mb-2 align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div style={{ width: "48px" }}>Ordo:</div>
            <Select
              showSearch
              placeholder="Select a Ordo"
              optionFilterProp="children"
              defaultValue={"gen"}
              onChange={(value) => setOrdo(value)}
              onSelect={(value, option) => {
                setOrdoDescription(option.description);
                setPageObj((oldState) => ({
                  ...oldState,
                  page: 1,
                }));
              }}
              filterOption={filterOption}
              options={ordoOptions}
              style={{ width: "100px", marginRight: "24px" }}
            />
            <div>{ordoDescription}</div>
          </div>
          {isAdministrator() ? (
            <div>
              <Button type="primary" icon={<PlusOutlined />} onClick={onNew}>
                Add New Event
              </Button>
            </div>
          ) : null}
        </div>
        <div className="d-flex align-items-center">
          <div style={{ width: "48px" }}>Date:</div>
          <RangePicker
            defaultValue={[
              dayjs(dataRange.start_date),
              dayjs(dataRange.end_date),
            ]}
            onChange={handleDateChange}
          />
        </div>
      </div>

      <div>
        <Spin spinning={loading}>
          <DashboardTable
            bordered
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1200,
            }}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageObj.limit,
              pageSizeOptions: ["15", "25", "50", "100"],
              total: total,
              current: pageObj.page,
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setPageObj({ page: current, limit: size });
              },
              onChange: (page, pageSize) => {
                handleChange(page, pageSize);
              },
            }}
            rowKey={(record) => record.id}
          />
        </Spin>
      </div>
      <CalendarModal
        isNew={isNew}
        editData={editData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setCurrentDate={setCurrentDate}
        ordoOptions={ordoOptions}
        rankOptions={rankOptions}
        seasonOptions={seasonOptions}
      />
    </div>
  );
};

export default Calendar;
