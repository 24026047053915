import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import { Modal, FloatButton } from "antd";
import Calendar from "./calendar/Calendar";
import Events from "./events/Events";
import MainTabs from "../../components/Tab/MainTabs";
import Celebrations from "./celebrations/Celebrations";
import Commons from "./commons/Commons";
import Ordos from "./ordos/Ordos";
import Overides from "./overides/Overides";
import verifyToken from "../../utils/verifyToken";

const Dashboard = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("1");
  const [logOutModal, setLogOutModal] = useState(false);

  useEffect(() => {
    const verify = async () => {
      let isValid = await verifyToken();
      if (!isValid) {
        navigate("/");
      }
    };

    verify();
  }, [navigate]);

  useEffect(() => {
    let current = localStorage.getItem("current_tab");

    if (current) {
      setCurrentTab(current);
    }
  }, []);

  const items = [
    {
      key: "1",
      label: "Calendar",
      children: <Calendar />,
    },
    {
      key: "2",
      label: "Liturgical Events",
      children: <Events />,
    },
    {
      key: "3",
      label: "Celebrations",
      children: <Celebrations />,
    },
    {
      key: "4",
      label: "Commons",
      children: <Commons />,
    },
    {
      key: "5",
      label: "Ordos",
      children: <Ordos />,
    },
    {
      key: "6",
      label: "Overides",
      children: <Overides />,
    },
  ];

  const handleChange = (key) => {
    localStorage.setItem("current_tab", key);
    setCurrentTab(key);
  };

  const handleOk = async () => {
    setLogOutModal(false);
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
  };

  const handleCancel = async () => {
    setLogOutModal(false);
  };

  return (
    <>
      <MainTabs
        type="card"
        defaultActiveKey="1"
        items={items}
        activeKey={currentTab}
        onChange={handleChange}
      />
      <FloatButton
        icon={<PoweroffOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={() => setLogOutModal(true)}
      />
      <Modal
        open={logOutModal}
        title="Logout"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Do you want to Log out?</p>
      </Modal>
    </>
  );
};

export default Dashboard;
