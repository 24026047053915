import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getOrdosByPagination = async (search, page, limit, setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/v2/ordos?search=${search}&page=${page}&limit=${limit}`
    );
    setLoading(false);

    return res.data;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const getOrdos = async (setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_API}/v2/ordos`);
    setLoading(false);

    return res.data.ordos;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const createOrdo = async (data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.post(`${process.env.REACT_APP_API}/v2/ordos`, data);

    if (res.status === 200) {
      Notification({ type: "success", message: "New Ordo is created!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const updateOrdo = async (id, data) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.put(
      `${process.env.REACT_APP_API}/v2/ordos/${id}`,
      data
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Ordo is updated!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const deleteOrdo = async (id) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    const res = await axios.delete(
      `${process.env.REACT_APP_API}/v2/ordos/${id}`
    );

    if (res.status === 200) {
      Notification({ type: "success", message: "Ordo is deleted!" });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};
