import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getOveridesByPagination = async (
  search,
  page,
  limit,
  setLoading
) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/v2/celebrations_override?search=${search}&page=${page}&limit=${limit}`
    );
    setLoading(false);

    return res.data;
  } catch (error) {
    console.log(error);
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};

export const executeOverides = async (ids, setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/celebrations_override`,
      ids
    );
    setLoading(false);

    if (res.status === 200) {
      Notification({
        type: "success",
        message: "Excuted the changes to Calendar successfully!",
      });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};

export const deleteOverides = async (ids, setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/celebrations_override/delete`,
      ids
    );
    setLoading(false);

    if (res.status === 200) {
      Notification({
        type: "success",
        message: "Deleted the override events successfully!",
      });
    }
  } catch (error) {
    Notification({ type: "error", message: "Server Error!" });
  }
};
