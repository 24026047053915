import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Input } from "antd";
import { changePassword } from "../../apis/authApi";
import Notification from "../Notification/Notification";

const ChangePasswordModal = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const onOk = async () => {
    form
      .validateFields()
      .then(async (data) => {
        const res = await changePassword(data.old_password, data.new_password);
        if (res === "success") {
          Notification({ type: "success", message: "Please sign in again" });
          localStorage.removeItem("idToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate("/");
        }
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancel = async () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Form name="form" {...formItemLayout} variant="filled" form={form}>
      <Modal
        title="Change Password"
        open={isModalOpen}
        okText="Ok"
        onOk={onOk}
        onCancel={onCancel}
        width={600}
      >
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[{ required: true, message: "Please input old password!" }]}
          style={{ marginTop: "8px", marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input new password!" },
            { min: 6, message: "Password should be over 6 characters" },
            {
              pattern: /[A-Z]/,
              message:
                "Password should contain at least one uppercase character!",
            },
            {
              pattern: /[a-z]/,
              message:
                "Password should contain at least one lowercase character!",
            },
            {
              pattern: /\d/,
              message: "Password should contain at least one number!",
            },
            {
              pattern: /[^A-Za-z0-9]/,
              message:
                "Password should contain at least one special character!",
            },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default ChangePasswordModal;
