import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Notification from "../components/Notification/Notification";

export const getSeasons = async (setLoading) => {
  try {
    setAuthToken(localStorage.getItem("idToken"));

    setLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_API}/v2/seasons`);
    setLoading(false);

    return res.data;
  } catch (error) {
    setLoading(false);

    Notification({ type: "error", message: "Server Error!" });
  }
};
