import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import AuthCode from "react-auth-code-input";
import {
  signin,
  confirm,
  signup,
  resened,
  forgotPassword,
  resetPassword,
} from "../../apis/authApi";
import verifyToken from "../../utils/verifyToken";
import BackImage from "../../assets/Surgeworks.png";

const Auth = () => {
  const navigate = useNavigate();

  const [status, setStatus] = useState(0); // 0:Sign In 1:Sign Up 2:Confirm 3:Reset Password1 4:Reset Password2
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const verify = async () => {
      let isValid = await verifyToken();
      if (isValid) {
        navigate("/dashboard");
      }
    };

    verify();
  }, [navigate]);

  const handleOnChange = (confirmCode) => {
    setCode(confirmCode);
  };

  const chagngeStatus = () => {
    if (status === 0) {
      setStatus(1);
    }
    if (status === 1) {
      setStatus(0);
    }
    if (status === 2) {
      setStatus(0);
    }
    setEmail("");
    setPassword("");
  };

  const onSubmit = async () => {
    if (status === 0) {
      if (email !== "" && password !== "") {
        const response = await signin(email, password, setLoading);
        if (response === "User not confirmed") {
          setStatus(2);
          await resened(email, setLoading);
        }

        if (response?.id_token) {
          localStorage.setItem("idToken", response.id_token);
          localStorage.setItem("accessToken", response.access_token);
          localStorage.setItem("refreshToken", response.refresh_token);
          navigate("/dashboard");
        }
      }
    }

    if (status === 1) {
      if (email !== "" && password !== "") {
        const response = await signup(email, password, setLoading);
        if (response === "success") setStatus(2);
      }
    }

    if (status === 2) {
      if (code !== "") {
        await confirm(email, code, setLoading);
        setStatus(0);
      }
    }

    if (status === 3) {
      let result = await forgotPassword(email);
      if (result === "success") {
        setStatus(4);
      } else {
        setStatus(0);
      }
    }

    if (status === 4) {
      resetPassword(code, email, password);
      setStatus(0);
    }
  };

  const OnReset = () => {
    setStatus(3);
  };

  return (
    <MDBValidation style={{ backgroundColor: "#f5f5f5" }}>
      <MDBContainer className="gradient-form d-flex align-items-center justify-content-center ">
        <MDBRow
          className="bg-white"
          style={{ height: "80%", minHeight: "660px" }}
        >
          <MDBCol className="p-0 col-md-6 col-12">
            <div className="d-flex flex-column m-5">
              <div className="text-center">
                <img src={BackImage} style={{ width: "185px" }} alt="logo" />
                <h3 className="mt-1 mb-5 pb-1">We are Surgeworks</h3>
              </div>

              {status === 2 ? (
                <AuthCode
                  onChange={handleOnChange}
                  containerClassName="code-container"
                  inputClassName="code-item"
                />
              ) : (
                <>
                  <MDBValidationItem feedback="Please input the email." invalid>
                    <MDBInput
                      value={email}
                      wrapperClass="mb-5"
                      label="Email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </MDBValidationItem>
                  {status !== 3 && (
                    <MDBValidationItem
                      feedback="Please input the password."
                      invalid
                    >
                      <MDBInput
                        value={password}
                        wrapperClass="mb-4"
                        label={status === 4 ? "New Password" : "Password"}
                        id="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </MDBValidationItem>
                  )}

                  {status === 4 ? (
                    <AuthCode
                      onChange={handleOnChange}
                      containerClassName="code-container"
                      inputClassName="code-item"
                    />
                  ) : null}
                </>
              )}

              <div className="text-center pt-1 mb-5 pb-1">
                {loading ? (
                  <MDBBtn className="mb-4 w-100 gradient-custom-2 disabled">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    className="mb-4 w-100 gradient-custom-2"
                    onClick={onSubmit}
                    type="submit"
                  >
                    {status === 0
                      ? "Sign In"
                      : status === 1
                      ? "Sign Up"
                      : status === 2
                      ? "Verify"
                      : "Reset"}
                  </MDBBtn>
                )}

                {status !== 3 ? (
                  <div onClick={chagngeStatus} className="mb-4">
                    {status === 0 ? "Go to Sign Up" : "Go to Sign In"}
                  </div>
                ) : null}

                <div onClick={OnReset}>
                  {status === 0 ? "Reset Password" : ""}
                </div>
              </div>
            </div>
          </MDBCol>

          <MDBCol className="p-0 col-md-6 col-12">
            <div
              className="d-flex flex-column  justify-content-center gradient-custom-2"
              style={{ height: "660px" }}
            >
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h3 className="mb-4">Meet our Liturgical Calendar API</h3>
                <p className="small mb-0">
                  Surgeworks specializes in rapid application development with
                  iOS SDK, Android SDK, Ruby on Rails, PHP, Magento and
                  WordPress.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBValidation>
  );
};

export default Auth;
