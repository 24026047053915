import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Layout } from "antd";
import {
  LockOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import verifyToken from "../../utils/verifyToken";

const { Footer, Content, Header } = Layout;

const headerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: " flex-end",
  padding: 0,
  background: "#0a3b24",
  maxHeight: "64px",
};

const avatarStyle = {
  background: "#308f59",
  marginRight: "12px",
};

const contentStyle = {
  margin: "24px 16px 0",
  backgroundColor: "#ffffff",
  minHeight: "calc(100vh - 152px)",
};

const footerStyle = {
  textAlign: "center",
  maxHeight: "64px",
  background: "#0a3b24",
  color: "white",
};

const MainLayout = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const verify = async () => {
      let isValid = await verifyToken();
      setIsShow(isValid);
    };

    verify();
  }, [navigate]);

  const changePassword = () => {
    setIsModalOpen(true);
  };

  const logout = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
  };

  const items = [
    {
      key: "0",
      label: "Change Password",
      icon: <LockOutlined />,
      onClick: changePassword,
    },
    {
      key: "1",
      label: "Log Out",
      icon: <PoweroffOutlined />,
      onClick: logout,
    },
  ];

  return (
    <Layout>
      <Header style={headerStyle}>
        {isShow ? (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Avatar
              style={avatarStyle}
              shape="square"
              size={36}
              icon={<UserOutlined />}
            />
          </Dropdown>
        ) : null}
      </Header>
      <Content style={contentStyle}>
        <Outlet />
      </Content>
      <Footer style={footerStyle}>
        Liturgical Calendar ©{new Date().getFullYear()} Created by Dane
      </Footer>
      <ChangePasswordModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Layout>
  );
};

export default MainLayout;
