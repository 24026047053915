import axios from "axios";
import Notification from "../components/Notification/Notification";

export const signin = async (email, password, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(`${process.env.REACT_APP_API}/v2/auth/login`, {
      email: email,
      password: password,
    });
    setLoading(false);

    Notification({ type: "success", message: "Sign in Successful!" });
    return res.data;
  } catch (error) {
    setLoading(false);

    if (error.response.status === 400) {
      if (error.response.data.detail === "User not confirmed") {
        return "User not confirmed";
      }

      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
  }
};

export const signup = async (email, password, setLoading) => {
  try {
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_API}/v2/auth/register`, {
      email: email,
      password: password,
    });
    setLoading(false);

    Notification({ type: "success", message: "Sign Up Successful!" });
    return "success";
  } catch (error) {
    setLoading(false);

    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }

    return "failure";
  }
};

export const confirm = async (email, code, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/auth/confirm`,
      { email: email, confirm_code: code }
    );
    setLoading(false);

    Notification({ type: "success", message: "Email Verified!" });
    return res.data;
  } catch (error) {
    setLoading(false);

    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
  }
};

export const resened = async (email, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/auth/resend`,
      { email: email }
    );
    setLoading(false);

    Notification({ type: "success", message: "Verificaiton Code is sent!" });
    return res.data;
  } catch (error) {
    setLoading(false);

    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
  }
};

export const changePassword = async (oldPass, newPass) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/v2/auth/change-password`, {
      access_token: localStorage.getItem("accessToken"),
      old_password: oldPass,
      new_password: newPass,
    });

    Notification({ type: "success", message: "The password is changed!" });

    return "success";
  } catch (error) {
    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
    return "failure";
  }
};

export const forgotPassword = async (email) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/v2/auth/forgot-password`, {
      email: email,
    });

    Notification({ type: "success", message: "Verificaiton Code is sent!" });

    return "success";
  } catch (error) {
    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
    return "failure";
  }
};

export const resetPassword = async (code, email, password) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/v2/auth/reset-password`, {
      confirmation_code: code,
      email: email,
      new_password: password,
    });

    Notification({ type: "success", message: "The password is reseted!" });

    return "success";
  } catch (error) {
    if (error.response.status === 400) {
      Notification({ type: "error", message: error.response.data.detail });
    } else {
      Notification({ type: "error", message: "Sever Error!" });
    }
    return "failure";
  }
};

export const refresh = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API}/v2/auth/refresh`,
      {
        refresh_token: localStorage.getItem("refreshToken"),
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error refreshing token", error);
  }
};
