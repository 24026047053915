import { ConfigProvider } from "antd";
import AppRoutes from "./AppRoutes";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#308f59",
            colorPrimaryHover: "#46b978",
          },
        }}
      >
        <AppRoutes />
      </ConfigProvider>
    </div>
  );
};

export default App;
