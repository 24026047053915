import { jwtDecode } from "jwt-decode";

const isAdministrator = () => {
  const token = localStorage.getItem("idToken");

  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    let groups = decoded["cognito:groups"];

    if (groups.length === 2) return true;

    return false;
  } catch (error) {
    console.error("Error decoding token!", error);
    return false;
  }
};

export default isAdministrator;
