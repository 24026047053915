import React, { useEffect } from "react";
import { Form, Modal, Input, Select, InputNumber, DatePicker } from "antd";
import dayjs from "dayjs";
import {
  createCalendarEvent,
  updateCalendarEvent,
} from "../../../apis/calendarApi";

const CalendarModal = ({
  isNew,
  editData,
  isModalOpen,
  setIsModalOpen,
  setCurrentDate,
  ordoOptions,
  rankOptions,
  seasonOptions,
}) => {
  const [form] = Form.useForm();
  const WEEKDAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if (isModalOpen && !isNew) {
      form.setFieldsValue(editData);
      form.setFieldValue("date", dayjs(editData.date));
    }
  }, [isNew, editData, isModalOpen, form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const onOk = async () => {
    form
      .validateFields()
      .then(async (data) => {
        let eventDate = data["date"];
        delete data["date"];
        data["year"] = eventDate.year();
        data["day"] = eventDate.format("YYYY-MM-DD");
        data["slug"] = data["label"];
        delete data["label"];
        data["precedence_id"] = data["precedence"];
        delete data["precedence"];
        data["rank_name"] = data["rank"];
        delete data["rank"];
        data["season_name"] = data["season"];
        delete data["season"];
        data["dow"] = WEEKDAYS[eventDate.day()];

        if (isNew) {
          await createCalendarEvent(data);
        } else {
          await updateCalendarEvent(editData.id, data);
        }
        form.resetFields();
        setIsModalOpen(false);
        setCurrentDate(new Date());
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancel = async () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Form name="calendar_form" {...formItemLayout} variant="filled" form={form}>
      <Modal
        title={isNew ? "New Calendar Event" : "Edit Calendar Event"}
        open={isModalOpen}
        okText="Ok"
        onOk={onOk}
        onCancel={onCancel}
        width={600}
      >
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: "Please input Date!" }]}
          style={{ marginBottom: "8px" }}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input title!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Precedence ID"
          name="precedence"
          rules={[
            { required: true, message: "Please input the precedence_id!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <InputNumber min={1} max={13} />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: true, message: "Please input color!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          initialValue={"gen"}
          rules={[{ required: true, message: "Please select the location!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            showSearch
            placeholder="Select a Location"
            // filterOption={filterOption}
            options={ordoOptions}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Ordo"
          name="ordo"
          initialValue={"gen"}
          rules={[{ required: true, message: "Please select the ordo!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            showSearch
            placeholder="Select a Ordo"
            optionFilterProp="children"
            defaultValue={"gen"}
            filterOption={filterOption}
            options={ordoOptions}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Rank"
          name="rank"
          initialValue={"Solemnity"}
          rules={[{ required: true, message: "Please select the rank!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            showSearch
            placeholder="Select a Rank"
            optionFilterProp="children"
            defaultValue={"Solemnity"}
            filterOption={filterOption}
            options={rankOptions}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Season"
          name="season"
          initialValue={"Advent"}
          rules={[{ required: true, message: "Please select the season!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            showSearch
            placeholder="Select a Season"
            optionFilterProp="children"
            defaultValue={"Advent"}
            filterOption={filterOption}
            options={seasonOptions}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
        <Form.Item
          label="Commons"
          name="commons"
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="label"
          rules={[{ required: true, message: "Please input slug!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default CalendarModal;
