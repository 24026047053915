import React, { useEffect } from "react";
import { Form, Modal, Input, Select } from "antd";
import { createOrdo, updateOrdo } from "../../../apis/ordoApi";

const OrdoModal = ({
  isNew,
  editData,
  isModalOpen,
  setIsModalOpen,
  setCurrentDate,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNew) {
      form.setFieldsValue(editData);
    }
  }, [isNew, editData, form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const onOk = async () => {
    form
      .validateFields()
      .then(async (data) => {
        if (isNew) {
          await createOrdo(data);
        } else {
          await updateOrdo(editData.id, data);
        }
        form.resetFields();
        setIsModalOpen(false);
        setCurrentDate(new Date());
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancel = async () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Form name="form" {...formItemLayout} variant="filled" form={form}>
      <Modal
        title={isNew ? "New Ordo" : "Edit Ordo"}
        open={isModalOpen}
        okText="Ok"
        onOk={onOk}
        onCancel={onCancel}
        width={600}
      >
        <Form.Item
          label="Ordo"
          name="id"
          rules={[{ required: true, message: "Please input ordo!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descriptioin"
          name="description"
          rules={[{ required: true, message: "Please input description!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Locale"
          name="locale"
          rules={[{ required: true, message: "Please input locale!" }]}
          style={{ marginBottom: "8px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Epiphany Placement"
          name="epiphany_placement"
          initialValue={"epiphany_Sunday"}
          rules={[
            { required: true, message: "Please select epiphany placement!" },
          ]}
          style={{ marginBottom: "8px" }}
        >
          <Select
            placeholder="Select a epiphany placement"
            optionFilterProp="children"
            defaultValue={"epiphany_Sunday"}
            options={[
              { label: "epiphany_Sunday", value: "epiphany_Sunday" },
              { label: "106", value: "106" },
            ]}
            style={{ width: "160px", marginRight: "24px" }}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default OrdoModal;
